<template>
  <button
    :class="['matrinx-in', fontFamilyCls]"
    @mousedown="mouseEventHandler('down')"
    @mouseup="mouseEventHandler('up')"
    @blur="mouseEventHandler('up')"
  >
    {{ propValue }}
  </button>
</template>

<script>
// import { mapState } from "vuex";
import { getCurComponentsInd } from '@/utils/utils';
import { fontFamilyMap } from '@/utils/attrNameData';
export default {
  props: {
    propValue: {
      type: String,
      default: "",
    },
    element: {
      type: Object,
      default: () => {},
    },
    isPreview: Boolean,
    currentComps: {
      type: String,
      require: false
    }
  },
  computed: {
    fontFamilyCls() {
      let cls = '';
      if (this.element.style && this.element.style.fontFamily) {
        cls = 'diy-' + fontFamilyMap[this.element.style.fontFamily];
      }
      return cls;
    }
  },
  // computed: mapState(["componentData", "curComponent"]),
  methods: {
    mouseEventHandler(type) {
      if (!this.isPreview) return;
      const all = this.currentComps ? JSON.parse(this.currentComps) : [];
      // const index = all.findIndex(
      //   (i) => i.id == this.element.id
      // );
      const ret = getCurComponentsInd(all, this.element);
      const {
        backgroundColor,
        backgroundImage,
        pressBackgroundImage,
        pressBackgroundColor,
        originBackImage,
        originBackColor,
      } = this.element.style || {};
      let obj = {};
      if (type == "down") {
        obj = {
          ...this.element,
          style: {
            ...this.element.style,
            originBackColor: backgroundColor,
            originBackImage: backgroundImage,
            backgroundImage: pressBackgroundImage,
            backgroundColor: pressBackgroundColor,
          },
        };
      } else {
        obj = {
          ...this.element,
          style: {
            ...this.element.style,
            backgroundImage: originBackImage,
            backgroundColor: originBackColor,
          },
        };
      }

      // all.splice(index, 1, obj);
      if (ret.parentInd !== -1) {
        // 生态组件下的子组件
        all[ret.parentInd].propValue.splice(ret.index, 1, obj);
      } else {
        all.splice(ret.index, 1, obj);
      }
      // this.$store.commit("setComponentData", all);
      this.$emit('changeCurComp', all);
    },
  },
};
</script>

<style lang='scss'>
.matrinx-in {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  transition: 0.1s;
  font-weight: 500;
  width: 100%;
  height: 100%;
  font-size: 14px;
  background-size: 100% 100%;
  &:active {
    color: #3a8ee6;
    border-color: #3a8ee6;
    outline: 0;
  }

  &:hover {
    background-color: #ecf5ff;
    color: #3a8ee6;
  }
}
</style>